import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Head from "../components/Head";
import Heading from "../components/Heading";

import PaymentGatewayLogo from "../images/paymentGateway-white.svg";
import styles from "./gateway.module.scss";

export const query = graphql`
  query($language: String!) {
    allMarkdownRemark(
      filter: {
        fields: {
          category: { eq: "text" }
          slug: { eq: "gateway" }
          language: { eq: $language }
        }
      }
    ) {
      edges {
        node {
          fields {
            slug
            language
          }
          frontmatter {
            title
            date
            header {
              title
              subtitle
              description
            }
            tabs {
              merchant
              user
            }
            sections {
              section {
                id
                title
                desc1
                desc2
                description
              }
            }
          }
        }
      }
    }
  }
`;

const GatewayPage = ({ data }) => {
  const pageContent = data.allMarkdownRemark.edges[0].node.frontmatter;
  const { header, sections } = pageContent;

  const section0 = sections.filter((element) => element.section.id === "0")[0]
    .section;
  const section1 = sections.filter((element) => element.section.id === "1")[0]
    .section;
  const section2 = sections.filter((element) => element.section.id === "2")[0]
    .section;
  const section3 = sections.filter((element) => element.section.id === "3")[0]
    .section;
  const section4 = sections.filter((element) => element.section.id === "4")[0]
    .section;

  const heading = (
    <Heading>
      <h1>
        <img className="icon" src={PaymentGatewayLogo} alt="" />
        {header.title}
      </h1>
      <h3>{header.subtitle}</h3>
      <h3>{header.description}</h3>
    </Heading>
  );

  return (
    <Layout heading={heading}>
      <Head title="Payment Gateway" />
      <div className={styles.mainContainer}>
        <div className={styles.merchantSection}>
          <h1>{section0.title}</h1>
          <p>{section0.desc1}</p>
          <p>{section0.desc2}</p>
        </div>

        <div className={styles.backgroundSection}>
          <div className={styles.bankSection}>
            <h1>{section1.title}</h1>
            <p>{section1.description}</p>
          </div>
          <div className={styles.cardsSection}>
            <h1>{section2.title}</h1>
            <p>{section2.description}</p>
          </div>
          <div className={styles.mobileMoneySection}>
            <h1>{section3.title}</h1>
            <p>{section3.description}</p>
          </div>
          <div className={styles.cashSection}>
            <h1>{section4.title}</h1>
            <p>{section4.description}</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default React.memo(GatewayPage);
